import { splitPhoneNumber } from "@/utils/phoneUtils";
import { viewSiteProfile } from "./../../../api/SitesApi";
import { defineComponent, onBeforeMount, ref } from "vue";

import StatusRectangle from "@/components/StatusRectangle/StatusRectangle.vue";
import CustomPopup from "@/components/CustomPopup/CustomPopup.vue";

import CalendarTodayIcon from "@/assets/icons/cards/CalendarToday.vue";
import EmailIcon from "@/assets/icons/cards/Email.vue";
import PhoneIcon from "@/assets/icons/cards/Phone.vue";
import UserIcon from "@/assets/icons/cards/User.vue";
import WorkOutlineIcon from "@/assets/icons/cards/WorkOutline.vue";
import CorporateFareIcon from "@/assets/icons/cards/CorporateFare.vue";
import EditIcon from "@/assets/icons/Edit.vue";

import { SiteContact } from "@/api/SiteContactsApi";
import { useRoute, useRouter } from "vue-router";
import {
  applyActionToUser,
  getOperationsForUserByStatus,
  getUserStatusAfterAction,
  UserAction,
  UserProfile,
  UserStatus,
} from "@/api/interfaces/UserInterfaces";
import { editUser, viewUserProfile } from "@/api/UsersApi";
import { useStore } from "@/store/store";
import usePhoneFormat from "@/uses/usePhoneFormatter";
import useUserRolesFormatter from "@/uses/useUserRolesFormatter";
import { MetadataMutations } from "@/store/metadataModule";
import useAddressFormatter from "@/uses/useAddressFormatter";
import { SiteProfile } from "@/api/interfaces/SitesInterfaces";
import { ActionItem } from "@/interfaces/ActionItem";
import { searchContacts } from "@/api/SiteContactsApi";
import useUserRoles from "@/uses/useUserRoles";
import { showError, showErrorOptional } from "@/utils/notificationUtils";

export default defineComponent({
  name: "ViewUser",
  props: {},
  components: {
    StatusRectangle,
    CustomPopup,
    CalendarTodayIcon,
    EmailIcon,
    PhoneIcon,
    UserIcon,
    WorkOutlineIcon,
    CorporateFareIcon,
    EditIcon,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const profile = ref<UserProfile>();
    const site = ref<SiteProfile>();
    const contact = ref<SiteContact | null>(null);
    const operations = ref<ActionItem[]>();
    const state = ref({
      deletePopup: {
        isVisible: false,
      },
    });

    const updateProfile = () => {
      viewUserProfile(route.params.id as string)
        .then((userProfile) => {
          profile.value = {
            ...userProfile,
            ...splitPhoneNumber(userProfile.phone_number),
          };

          store.commit(
            MetadataMutations.SET_BREAD_CRUMBS_NAME,
            `${profile.value.first_name} ${profile.value.last_name}`
          );

          operations.value = getOperationsForUserByStatus(
            userProfile.status as UserStatus,
            store.state.auth.userRoles || []
          ).filter(
            (o) => o.action !== UserAction.View && o.action !== UserAction.Edit
          );

          if (userProfile.site_id) {
            viewSiteProfile(userProfile.site_id as string).then(
              (siteProfile) => {
                site.value = siteProfile;

                searchContacts(userProfile.site_id as string).then(
                  (contacts) => {
                    if (contacts.length) {
                      contact.value =
                        contacts.find((c) => c.is_primary_contact) ||
                        contacts[0];
                      console.log(contact.value);
                    }
                  }
                );
              }
            );
          }
        })
        .catch((ex) => {
          showErrorOptional("User is not found", ex);
        });
    };

    onBeforeMount(() => {
      updateProfile();
    });

    return {
      state,
      profile,
      site,
      contact,
      operations,
      UserAction,
      UserStatus,
      ...usePhoneFormat(),
      ...useUserRolesFormatter(),
      ...useAddressFormatter(),
      ...useUserRoles(store),
      setContactPerson: async (value: boolean) => {
        try {
          console.log(profile.value);
          await editUser(profile.value as UserProfile, profile.value?.site_id);

          if (profile.value) {
            profile.value.is_site_contact_person = value;
          }
        } catch (ex) {
          console.log("error during update contact person", ex);
          if (profile.value) {
            profile.value.is_site_contact_person = !value;
          }
        }
      },
      handleAction: (action: UserAction) => {
        if (profile.value?.id) {
          // if (action === UserAction.Delete) {
          //   state.value.deletePopup.isVisible = true;
          // } else {
          applyActionToUser(
            action,
            profile.value,
            store.state.auth.userRoles || []
          ).then(() => {
            if (profile.value) {
              profile.value.status = getUserStatusAfterAction(
                profile.value.status as UserStatus,
                action
              );

              operations.value = getOperationsForUserByStatus(
                profile.value.status as UserStatus,
                store.state.auth.userRoles || []
              ).filter(
                (o) =>
                  o.action !== UserAction.View && o.action !== UserAction.Edit
              );
            }
          });
          // }
        }
      },
      // deleteFromPopup(needToDelete: boolean) {
      //   if (needToDelete) {
      //     applyActionToUser(
      //       UserAction.Delete,
      //       profile.value as UserProfile,
      //       store.state.auth.userRoles || []
      //     );
      //     router.back();
      //   }
      //   state.value.deletePopup.isVisible = false;
      // },
    };
  },
});
