import { MetadataMutations } from "./../../../store/metadataModule";
import { defineComponent, onBeforeMount, ref } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";

import { useStore } from "@/store/store";
import { UserProfile } from "@/api/interfaces/UserInterfaces";
import { viewUserProfile } from "@/api/UsersApi";

import UserForm from "./../UserForm/UserForm.vue";

export default defineComponent({
  name: "EditUser",
  props: {},
  components: { UserForm },
  setup() {
    const route = useRoute();
    const store = useStore();

    const profile = ref<UserProfile>();

    onBeforeMount(() => {
      viewUserProfile(route.params.id as string).then((userProfile) => {
        profile.value = userProfile;
        store.commit(
          MetadataMutations.SET_BREAD_CRUMBS_NAME,
          `${profile.value.first_name} ${profile.value.last_name}`
        );
      });
    });

    return {
      profile,
    };
  },
});
