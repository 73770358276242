import { Address } from "@/interfaces/Address";

export default function useAddressFormatter() {
  return {
    formatAddress(address: Address) {
      return [
        address.region,
        address.city,
        address.address_line1,
        address.address_line2,
        address.zip,
      ]
        .filter((s) => s)
        .join(", ");
    },
  };
}
